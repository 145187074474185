/* styles/tailwind.css */
@tailwind base; 
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Bariol;
        font-style: normal; 
        src: url('./assets/fonts/Bariol/bariol_regular-webfont.ttf')format('truetype');
    }
    
    @font-face {
        font-family: Bariol;
        font-style: italic;
        src: url('./assets/fonts/Bariol/bariol_regular_italic-webfont.ttf')format('truetype');
    }

    @font-face {
        font-family: Bariol;
        font-style: normal; 
        font-weight: 700;
        src: url('./assets/fonts/Bariol/bariol_bold-webfont.ttf') format('truetype');
    }

    @font-face {
        font-family: Bariol;
        font-style: italic; 
        font-weight: 700;
        src: url('./assets/fonts/Bariol/bariol_bold_italic-webfont.ttf') format('truetype');
    }

}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: bariol;
}

